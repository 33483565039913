import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
import { BASE_URL } from "@/service_urls";
import campaign from '@/services/campaign';

const state = {
    campaignById: {},
    campaigns: [],
    campaignFields: [],
    campaignFieldsData: [],
    campaignsCount: 0,
    fromCampaignPage: false,
    candidateData: {},
    regCompletedCandidate: {},
    campaignWelcomeDetails: {},
};

const getters = {
    getCampaignById: state => state.campaignById,
    getCampaigns: state => state.campaigns,
    getCampaignFields: state => state.campaignFields,
    campaignFieldsData: state => state.campaignFieldsData,
    getCampaignsCount: state => state.campaignsCount,
    fromCampaignPage: state => state.fromCampaignPage,
    getCandidateData: state => state.candidateData,
    getCampaignWelcomeDetails: state => state.campaignWelcomeDetails
};

const actions = {
    fetchCampaignById({ commit }, campaign_id) {
        return campaign.fetchCampaignById(campaign_id)
            .then(res => {
                const { data } = res
                commit("SET_CAMPAIGN_BY_ID", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching campaigns", err)
                return err
            })
    },
    fetchCampaigns({ commit, getters }, payload = {}) {
        const { getCustomerId, getOrgID } = getters;
        const { searchTerm, skip, limit, is_active } = payload;
        let queryPayload = {
            all_rows: false,
            fetch_row_count: false,
            customer_id: getCustomerId,
        }
        if (searchTerm) {
            queryPayload = { ...queryPayload, searchTerm };
        }
        if (skip !== undefined) {
            queryPayload = { ...queryPayload, skip };
        } else {
            queryPayload = { ...queryPayload, skip: 0 };
        }
        if (limit) {
            queryPayload = { ...queryPayload, limit };
        }
        if (getOrgID) {
            queryPayload = { ...queryPayload, organisation_id: getOrgID }
        }
        if (is_active !== undefined) {
            queryPayload = { ...queryPayload, is_active };
        }
        const query = getFilterQueryStringWithoutArray(queryPayload)
        return campaign.fetchCampaigns(query)
            .then(res => {
                const { data } = res
                commit("SET_CAMPAIGNS", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching campaigns", err)
                return err
            })
    },
    fetchCampaignFields({ commit }, campaign_id) {
        return campaign.fetchCampaignFields(campaign_id)
            .then(res => {
                const { data } = res
                commit("SET_CAMPAIGN_FIELDS", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching campaigns", err)
                return err
            })
    },
    createCampaignTransactions({ dispatch, getters }, { campaign_id, candidate_uid, completed, payload, queryPayload }) {
        const {getCampaignById} = getters
        const uuid = getCampaignById?.campaign_uuid
        let queryData = {
            campaign_id,
            uuid,
            ...queryPayload,
        }
        if (completed !== null) {
            queryData.completed = completed;
        }
        if(candidate_uid){
            queryData = {
                ...queryData,
                candidate_uid
            }
        }
        const queryString = getFilterQueryStringWithoutArray(queryData)
        let url = `${BASE_URL}/campaign_transactions/?${queryString}`;

        return campaign.createCampaignTransactions(url, payload)
            .then(res => {
                const { data } = res
                dispatch("showToast", { class: 'bg-success text-white', message: 'Registered Successfully' })
                return res;
            })
            .catch(err => {
                const message = "Error While Submit Campaign Form";
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log(message, err);
                return err
            })
    },
    registerCampaign: ({ getters, commit, dispatch }, payload) => {
        const {
            campaign_name,
            customer_id,
            organisation_id,
            logo,
            background_image,
            campaign_registration_required,
            description,
            welcome_text,
        } = payload;

        let queryPayload = { campaign_name, customer_id, campaign_registration_required };
        if (organisation_id) {
            queryPayload = { ...queryPayload, organisation_id }
        }
        if (description) {
            queryPayload = { ...queryPayload, description }
        }
        if (welcome_text) {
            queryPayload = { ...queryPayload, welcome_text }
        }

        const queryString = getFilterQueryStringWithoutArray(queryPayload);

        const formData = new FormData();
        if (logo) {
            const { file, fileName } = logo
            formData.append("logo", file, fileName);
        } else {
            formData.append("logo", '')
        }
        if (background_image) {
            const { file, fileName } = background_image
            formData.append("background_image", file, fileName);
        } else {
            formData.append("background_image", '')
        }

        return campaign
            .registerCampaign(queryString, formData)
            .then((res) => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Created Campaign Form Successfully",
                });
                return res.data;
            })
            .catch((err) => {
                const message = err?.response?.status == 409 ? err?.response?.data?.detail : "Error While Create Campaign Form"
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log("Error While Create Campaign Form", err);
                return err;
            });
    },
    initCampaign: ({ getters, commit, dispatch }, campaign_id) => {
        const { questionTypes } = getters;
        let appendAction = [];
        commit("SET_FETCHING", true);
        if (!questionTypes.length)
            appendAction = [...appendAction, dispatch("fetchQuestionTypes", true)];
        Promise.all(appendAction).then(res => {
            commit("SET_FETCHING", false);
            return res;
        });
    },
    createCampaignField: ({ getters, commit, dispatch }, data) => {
        const { payload, campaign_id } = data;
        return campaign
            .createCampaignField(campaign_id, payload)
            .then((res) => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Campaign Field Created Successfully",
                });
                return res;
            })
            .catch((err) => {
                const message = err.response.data.detail
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log(message, err);
                return err;
            });
    },
    updateCampaignField: ({ getters, commit, dispatch }, data) => {
        const { payload, campaign_id } = data;
        return campaign
            .updateCampaignField(campaign_id, payload)
            .then((res) => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Campaign Field Updated Successfully",
                });
                return res;
            })
            .catch((err) => {
                const message = "Error While Updated Campaign Field"
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log(message, err);
                return err;
            });
    },
    updateCampaignData: ({ dispatch }, payload) => {
        const { logo, background_image, campaign_uuid, ...rest } = payload;

        const queryString = getFilterQueryStringWithoutArray({ ...rest });

        let formData = null;
        if ((logo && logo.file) || (background_image && background_image.file)) {
            formData = new FormData();
            if (logo) {
                const { file, fileName } = logo;
                formData.append("logo", file, fileName);
            }
            if (background_image) {
                const { file, fileName } = background_image;
                formData.append("background_image", file, fileName);
            }
        }
        return campaign
            .updateCampaignData(campaign_uuid, queryString, formData)
            .then((res) => {
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Campaign Field Updated Successfully",
                });
                return res;
            })
            .catch((err) => {
                const message = err.response.data.detail;
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                console.log(message, err);
                return err;
            });
    },
    deleteCampaignField({ dispatch }, campaign_field_id) {
        dispatch("showLoader")
        return campaign
            .deleteCampaignField(campaign_field_id)
            .then((response) => {
                dispatch("hideLoader")
                dispatch("showToast", {
                    class: "bg-success text-white",
                    message: "Campaign Field Deleted Successfully",
                });
                return response;
            })
            .catch((error) => {
                dispatch("hideLoader")
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "Error While Deleting Campaign Field",
                });
                console.log("Error While Deleting Campaign Field", error);
                return error;
            });
    },
    setCampaignFieldsData: ({ commit }, payload) => {
        commit("SET_FROM_CAMPAIGN_PAGE", true);
        commit("SET_CAMPAIGN_FIELDS_DATA", payload);
    },
    clearCampaignFieldsData({ commit }) {
        commit("CLEAR_CAMPAIGN_FIELDS_DATA");
    },
    downloadCampaignData({ dispatch }, { campaign_name, campaign_id, }) {
        dispatch("showFixedToast", {
            class: 'bg-secondary text-white',
            message: 'Preparing to Download...'
        });
        return campaign.downloadCampaignData(campaign_id)
            .then(res => {
                const fileName = `${campaign_name}.xlsx`;
                const link = document.createElement("a");
                const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", fileName);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch("hideFixedToast");
                } else {
                    dispatch("hideFixedToast");
                    dispatch("showToast", {
                        class: 'bg-danger text-white',
                        message: 'Your browser does not support downloading files.'
                    });
                }
            })
            .catch(err => {
                dispatch("hideFixedToast");
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message: 'Download failed. Please try again.'
                });
                console.error("Error in downloading campaign data:", err);
                return err;
            });
    },
    fetchCampaignsCount({ commit, getters }, payload = {}) {
        const { getCustomerId, getOrgIDFromAccessToken } = getters;
        const { searchTerm, is_active } = payload;
        let queryPayload = {
            all_rows: true,
            fetch_row_count: true,
            customer_id: getCustomerId,
            organisation_id: getOrgIDFromAccessToken,
        }
        if (searchTerm) {
            queryPayload = { ...queryPayload, searchTerm };
        }
        if (is_active !== undefined) {
            queryPayload = { ...queryPayload, is_active };
        }
        const query = getFilterQueryStringWithoutArray(queryPayload)
        return campaign.getCampaignsCount(query)
            .then(res => {
                const { data } = res
                commit("SET_CAMPAIGNS_COUNT", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching campaigns count", err)
                return err
            })

    },
    getCampaignsByEmail({ commit, getters }, payload) {
        let { email, ...rest } = payload
        let query = getFilterQueryStringWithoutArray({ email: encodeURIComponent(email), ...rest })
        return campaign.getCampaignsByEmail(query)
            .then(res => {
                return res.data
            })
            .catch(err => {
                console.log("Error while fetching campaigns by email", err)
                return err
            })
    },
    getCampaignsDataByCampaignUserID({ commit, getters }, payload) {
        const query = getFilterQueryStringWithoutArray(payload)
        return campaign.getCampaignsDataByCampaignUserID(query)
            .then(res => {
                const { data } = res
                commit("SET_CAMPAIGN_FIELDS_DATA", data);
                return res.data
            })
            .catch(err => {
                console.log("Error while fetching campaigns by email", err)
                return err
            })
    },
    checkCandidateExistInCampaign({ commit }, payload) {
        const { email, campaign_id } = payload;
        let queryString = ''
        if (campaign_id) {
            queryString = getFilterQueryStringWithoutArray({ campaign_id, email: encodeURIComponent(email) });
        } else {
            queryString = getFilterQueryStringWithoutArray({ email: encodeURIComponent(email) });
        }
        return campaign.checkCandidateExistInCampaign({ queryString })
            .then(res => {
                const { data } = res
                commit("SET_CANDIDATE_DATA", data);
                return res.data
            })
            .catch(err => {
                console.log("Error while checking candidate exist in candidate or campaign", err)
                return err
            })
    },
    updateCampaignPreRegCompleted({ commit }, payload) {
        const { email, updated_by } = payload;
        let queryString = ''
        if (updated_by) {
            queryString = getFilterQueryStringWithoutArray({ updated_by, email: encodeURIComponent(email) });
        } else {
            queryString = getFilterQueryStringWithoutArray({ email: encodeURIComponent(email) });
        }
        return campaign.updateCampaignPreRegCompleted({ queryString })
            .then(res => {
                const { data } = res
                return data
            })
            .catch(err => {
                console.log(`Error while update "completed" in campaign_pre_reg`, err)
                return err
            })
    }
};

const mutations = {
    ["SET_CAMPAIGN_BY_ID"](state, payload) {
        state.campaignById = payload;
    },
    ["SET_CAMPAIGNS"](state, payload) {
        state.campaigns = payload;
    },
    ["SET_CAMPAIGN_FIELDS"](state, payload) {
        state.campaignFields = payload;
    },
    ["SET_CAMPAIGN_FIELDS_DATA"](state, payload) {
        state.campaignFieldsData = payload;
    },
    ["SET_FROM_CAMPAIGN_PAGE"](state, payload) {
        state.fromCampaignPage = payload;
    },
    ["CLEAR_CAMPAIGN_FIELDS_DATA"](state) {
        state.campaignFieldsData = {};
        state.fromCampaignPage = false;
    },
    ["SET_CAMPAIGNS_COUNT"](state, payload) {
        state.campaignsCount = payload;
    },
    ["SET_CANDIDATE_DATA"](state, payload) {
        state.candidateData = payload;
    },
    ["SET_CAMPAIGN_WELCOME_DATA"](state, payload) {
        state.campaignWelcomeDetails = payload;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
